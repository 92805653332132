import React from 'react';
import { Fiche, useFicheServiceStatus } from '@jvs-group/jvs-mairistem-fiche'; // useCreateFiche
import { PieceJointeBar } from '@jvs-group/jvs-mairistem-comptabilite';
import {
  FichePanelComplements,
  Fichiers,
  PANEL_TYPE,
  TYPE_GABARIT,
  getNatureFromGabarit,
  useGabaritPj,
} from '@jvs-group/jvs-mairistem-finances-utils';
import ProjetInformation from '@/src/components/ProjetFichePanel/ProjetInformation/ProjetInformation';
import { Projet } from '@/src/interfaces/projet';
import Commentaires from '@/src/components/Common/Commentaires';
import Financement from '@/src/components/ProjetFichePanel/Financement/Financement';
import EcrituresComptables from '@/src/components/Common/EcrituresComptables/EcrituresComptables';
import BaseProfilEcritureProjet from '@/src/classes/ProfilEcriture/BaseProfilEcritureProjet';
import projetService from '@/src/services/projetService';
// import subventionService from '@/src/services/subventionService';
import ProjetListeContainer from '@/src/components/ProjetListe/ProjetListeContainer';

interface ProjetFicheConsumerProps {
  projet?: Projet,
  errors?: any,
  mutation?: any,
}

const ProjetFicheConsumer = ({
  projet,
  mutation = null,
  errors = null,
  ...rest
} : ProjetFicheConsumerProps) => {
  const {
    identifiant,
    libelle,
    montantHT,
    montantTVA,
    montantTTC,
    dateFin,
    identifiantCodeTVA,
    etiquettes,
    identifiantEntite = parseInt(sessionStorage.getItem('identifiantEntite'), 10),
    dateCreation,
    dateExecution,
    status,
    identifiantDocInfo,
    referenceInterne,
    utilisateursProjet,
    deletedUtilisateursProjet,
    composants,
    deletedComposants,
    identifiantService,
  } = projet || {};

  // const createFiche = useCreateFiche(subventionService, { path: '/subventionsPercues' });

  const [profilEcriture, setProfilEcriture] = React.useState(new BaseProfilEcritureProjet());
  const [, gabaritsPj] = useGabaritPj([TYPE_GABARIT.PROJET]);

  const handleChange = React.useCallback((e) => {
    mutation?.update({ identifiant, ...e });
  }, [mutation, identifiant]);

  const handleChangePj = React.useCallback((changes) => {
    handleChange({
      identifiant,
      ...changes,
    });
  }, [identifiant, handleChange]);

  // const handleCreateSubvention = React.useCallback(() => {
  //   createFiche({
  //     identifiantProjet: identifiant,
  //     composants,
  //     projet: {
  //       identifiant,
  //     },
  //   });
  // }, [createFiche, identifiant, composants]);

  const information = React.useMemo(() => (
    <Fiche.Panel key="general" id="general" title="Général">
      <ProjetInformation
        projet={{
          identifiant,
          libelle,
          montantHT,
          montantTVA,
          montantTTC,
          dateFin,
          identifiantCodeTVA,
          etiquettes,
          identifiantEntite,
          dateCreation,
          dateExecution,
          status,
          identifiantDocInfo,
          referenceInterne,
          utilisateursProjet,
          deletedUtilisateursProjet,
          composants,
          deletedComposants,
          identifiantService,
        }}
        onChange={handleChange}
        errors={errors}
      />
    </Fiche.Panel>
  ), [
    libelle,
    montantHT,
    montantTVA,
    montantTTC,
    dateFin,
    identifiantCodeTVA,
    errors,
    identifiant,
    handleChange,
    etiquettes,
    identifiantEntite,
    dateCreation,
    dateExecution,
    status,
    identifiantDocInfo,
    referenceInterne,
    utilisateursProjet,
    deletedUtilisateursProjet,
    composants,
    deletedComposants,
    identifiantService,
  ]);

  const ficheServiceStatus = useFicheServiceStatus(projetService, identifiant);

  const financement = React.useMemo(() => (
    <Fiche.Panel key="financement" id="financement" title="PLAN DE FINANCEMENT">
      <Financement projet={projet} />
      {/* onCreateSubvention={handleCreateSubvention} */}
    </Fiche.Panel>
  ), [
    projet,
    // handleCreateSubvention,
  ]);

  const ecrituresComptables = React.useMemo(() => (
    <Fiche.Panel key="ecritures" id="ecritures" title="ECRITURES COMPTABLES">
      <EcrituresComptables
        projet={projet}
        conditionsVersements={projet?.subventions?.map((s) => s.conditionsVersements)?.flat()}
        montantSubvention={projet?.subventions?.reduce((acc, current) => acc + current.mntAttribue, 0)}
        realisationHT
        montantProjet={projet?.montantHT ?? 0}
      />
    </Fiche.Panel>
  ), [
    projet,
  ]);

  const voletPolitique = React.useMemo(() => (
    <Fiche.Panel key="voletPolitique" id="voletPolitique" title="volet politique">
      <FichePanelComplements
        codeProfilEcriture="PRO"
        data={projet}
        handleProfilEcritureChange={setProfilEcriture}
        identifiantEntite={identifiantEntite}
        identifiantFiche={identifiant}
        onChange={handleChange}
        panelType={PANEL_TYPE.SUBVENTION}
        profilEcriture={profilEcriture}
      />
    </Fiche.Panel>
  ), [
    handleChange,
    identifiant,
    identifiantEntite,
    profilEcriture,
    projet,
  ]);

  const commentaires = React.useMemo(() => (
    <Fiche.Panel key="commentaires" id="commentaires" title="COMMENTAIRES">
      <Commentaires
        identifiant={projet?.identifiant}
        commentaire={projet?.commentaire}
        infosPubliques={projet?.infosPubliques}
        onChange={handleChange}
      />
    </Fiche.Panel>
  ), [
    projet?.identifiant,
    handleChange,
    projet?.commentaire,
    projet?.infosPubliques,
  ]);

  const getPjSideBar = React.useCallback(() => (
    <PieceJointeBar
      identifiantFichierLie={identifiant}
      nomFichierLie={Fichiers.PROJET}
      types={getNatureFromGabarit(gabaritsPj?.find((g) => g.type === TYPE_GABARIT.PROJET))}
      onChange={handleChangePj}
      typeGabarit={TYPE_GABARIT.PROJET}
    />
  ), [
    identifiant,
    handleChangePj,
    gabaritsPj,
  ]);

  let header = null;
  if (ficheServiceStatus.created && !ficheServiceStatus.updated) {
    header = 'Nouveau projet';
  } else {
    header = `Projet ${libelle}`;
  }

  const sidebars = React.useMemo(() => ({
    left: <ProjetListeContainer />,
    right: {
      content: getPjSideBar(),
      icon: 'attach',
      alwaysPinned: true,
      width: '15vw',
    },
  }), [getPjSideBar]);

  return (
    <span
      className="ficheWithSideBar"
    >
      <Fiche
        key="ficheProjet"
        type="projet"
        header={header}
        errors={errors}
        sidebars={sidebars}
        {...rest}
      >
        {information}
        {financement}
        {ecrituresComptables}
        {voletPolitique}
        {commentaires}
      </Fiche>
    </span>
  );
};

export default ProjetFicheConsumer;
