import React from 'react';

import {
  useAutofocus,
} from '@jvs-group/jvs-mairistem-comptabilite';
import {
  Form,
  Tooltip,
  Grid,
  Input,
} from '@jvs-group/jvs-mairistem-composants';
import { ErrorField } from '@jvs-group/jvs-mairistem-fiche/lib/ts/types';
import { Combobox, Date } from '@jvs-group/jvs-mairistem-finances-utils';
import { request } from '@jvs-group/jvs-mairistem-store';
import { toast } from 'react-toastify';
import { getTooltipError, isError } from '@/src/utils/tools';
import EtiquettesInput from '@/src/components/Common/EtiquettesInput';
import { Projet } from '@/src/interfaces/projet';
import Avatar from '@/src/components/Common/Avatar';
import UtilisateurProjetInput from '@/src/components/ProjetFichePanel/UtilisateurProjetInput/UtilisateurProjetInput';
import ProjetComposant from '@/src/components/ProjetFichePanel/ProjetComposant/ProjetComposant';
import { PROJET_STATUS_OPTIONS } from '@/src/constants/projetStatus';
import { Service } from '@/src/interfaces/service';

type PickedProjetKeys = 'identifiant' |
'libelle' |
'montantHT' |
'montantTVA' |
'montantTTC' |
'dateFin' |
'identifiantCodeTVA' |
'etiquettes' |
'dateCreation' |
'dateExecution' |
'status' |
'identifiantDocInfo' |
'referenceInterne' |
'status' |
'identifiantEntite' |
'utilisateursProjet' |
'composants' |
'deletedComposants' |
'deletedUtilisateursProjet'|
'identifiantService';

interface ProjetInformationProps {
  projet: Pick<Projet, PickedProjetKeys>,
  onChange: (any) => void,
  errors: Array<ErrorField>,
}

const ProjetInformation = ({
  projet = null,
  onChange = null,
  errors,
} : ProjetInformationProps) => {
  const mainInput = useAutofocus([projet?.identifiant]);
  const [services, setServices] = React.useState<Service[]>([]);

  const handleChange = (e, { name, value }) => {
    if (onChange) { onChange({ [name]: value }); }
  };

  const handleChangeUserService = (e, { name, value }) => {
    if (onChange) { onChange({ [name]: (value === '' ? null : value) }); }
  };

  const handleChangeDate = (value: string, name: string) => onChange?.({ [name]: value });

  React.useEffect(
    () => {
      request.get(
        '/api/finances/comptabilite/piecescomptables/services/getServicesAccordingToRole',
      )
        .then((data: Service[]) => {
          setServices(data);
        })
        .catch(() => {
          toast.error('Erreur lors de la récupération des services utilisateurs.');
        });
    },
    [],
  );

  return (
    <Form>
      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column width={12}>
            <Form.Group>

              <Tooltip
                {...getTooltipError(errors, 'subventionsPercues-referenceDossier')}
                trigger={(
                  <Form.Field
                    label="Intitulé"
                    type="text"
                    // @ts-expect-error
                    control={Input}
                    placeholder="Intitulé du projet"
                    name="libelle"
                    value={projet?.libelle ?? ''}
                    required
                    error={isError(errors, 'subventionsPercues-referenceDossier')}
                    onChange={handleChange}
                    fluid
                    debounced
                    width={16}
                    input={{ ref: mainInput, 'data-testid': 'libelleProjet' }}
                  />
                  )}
              />
            </Form.Group>

            <Form.Field
              label="Référence interne"
              type="text"
              placeholder="Référence interne"
              name="referenceInterne"
              // @ts-expect-error
              control={Input}
              value={projet?.referenceInterne ?? ''}
              onChange={handleChange}
              fluid
              width={16}
              input={{ 'data-testid': 'referenceInterneProjet' }}
            />

            <UtilisateurProjetInput
              onChange={onChange}
              utilisateursProjet={projet?.utilisateursProjet}
              deletedUtilisateursProjet={projet?.deletedUtilisateursProjet}
              identifiant={projet?.identifiant}
            />

            <Form.Field
              // @ts-expect-error
              name="status"
              control={Combobox}
              label="Stade"
              placeholder="Stade"
              fluid
              search
              selection
              value={projet?.status ?? null}
              options={PROJET_STATUS_OPTIONS}
              data-testid="statusProjetDropdown"
              onChange={handleChange}
            />

            <Form.Group>
              <Form.Field
                label="Date de création"
                width={7}
                control={Date}
                // @ts-expect-error
                name="dateCreation"
                value={projet?.dateCreation}
                onChange={handleChangeDate}
                fluid
                data-testid="dateCreationProjet"
              />

              <Form.Field
                label="Commencement d'exécution"
                control={Date}
                width={4}
                // @ts-expect-error
                name="dateExecution"
                value={projet?.dateExecution}
                onChange={handleChangeDate}
                fluid
                data-testid="dateExecutionProjet"
              />

              <Tooltip
                {...getTooltipError(errors, 'subventionspercues-dateFin')}
                trigger={(
                  <Form.Field
                    control={Date}
                    // @ts-expect-error
                    name="dateFin"
                    label="Achèvement de l'opération"
                    value={projet?.dateFin}
                    onChange={handleChangeDate}
                    fluid
                    width={5}
                    data-testid="dateFinProjet"
                  />
                  )}
              />
            </Form.Group>

            <EtiquettesInput
              identifiantEntite={projet?.identifiantEntite}
              onChange={onChange}
              selectedEtiquettes={projet?.etiquettes}
              allowAdditions
            />
            {
              services.length > 0 && (
              <Form.Field
                // @ts-expect-error
                name="identifiantService"
                control={Combobox}
                label="Service utilisateur"
                placeholder="Service utilisateur"
                fluid
                selection
                clearable
                value={projet?.identifiantService ?? null}
                options={services?.map((service) => (
                  {
                    key: service.identifiant,
                    text: service.libelle,
                    value: service.identifiant,
                  }
                ))}
                data-testid="userService"
                onChange={handleChangeUserService}
              />
              )
            }
          </Grid.Column>

          <Grid.Column width={4}>
            <div className="avatarWrapper">
              <Avatar
                identifiant={projet?.identifiant}
                identifiantDocInfo={projet?.identifiantDocInfo}
                handleChange={onChange}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <ProjetComposant
              composants={projet?.composants}
              deletedComposants={projet?.deletedComposants}
              onChange={onChange}
              status={projet?.status}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default ProjetInformation;
