import React from 'react';
import {
  TiersContactAdresseListe,
  TYPE_TIERS_DEBITEUR,
  TYPE_TIERS_DEBITEUR_FOURNISSEUR,
} from '@jvs-group/jvs-mairistem-comptabilite';

interface SubventionFinanceurProps {
  financeur: any,
  onChange: any,
}

const SubventionFinanceur = ({ financeur = null, onChange = null } : SubventionFinanceurProps) => {
  const handleUpdate = (tiers) => {
    if (onChange) {
      onChange({ target: { name: 'identifiantFinanceur', value: tiers?.identifiant } });
      onChange({ target: { name: 'financeur', value: tiers } });
    }
  };

  return (
    <TiersContactAdresseListe
      title="Financeur"
      tiers={financeur}
      filterType={[TYPE_TIERS_DEBITEUR, TYPE_TIERS_DEBITEUR_FOURNISSEUR]}
      onUpdate={handleUpdate}
      mode="explorer"
    />
  );
};

export default SubventionFinanceur;
