import React from 'react';
import { Liste } from '@jvs-group/jvs-mairistem-liste';
import { Header } from '@jvs-group/jvs-mairistem-composants';
import { useHistory } from 'react-router-dom';
import ProjetListeTable from '@/src/components/ProjetListe/ProjetListeTable';
import { Projet } from '@/src/interfaces/projet';

interface ProjetListeProps {
  projets: Array<Projet>,
  count: number,
  perPage: number,
  loading: boolean,
  onLoad: any,
  onSearch: any,
  onSort: any,
  onSelected: any,
  onExplored: any,
  onExpanded: any,
  state: any,
  mode: any,
  query? : any
}

const ProjetListe = (props : ProjetListeProps) => {
  const {
    projets,
    count,
    perPage,
    loading,
    onLoad,
    onSort,
    onSelected,
    mode,
    // query,
    ...handlers
  } = props;

  // const [selectedEtiquettes, setSelectedEtiquettes] = useState<Etiquette[]>([]);
  // const [selectedStades, setSelectedStades] = useState<number[]>([0, 1, 2, 3]);

  // const handleQueryChange = (etiquettes: Etiquette[], stades: number[]) => {
  //   query.filter(() => ({
  //     ...(etiquettes.length > 0)
  //         && { 'etiquettesId[]': etiquettes.map((etiquette) => etiquette.identifiant) },
  //     ...(stades.length > 0)
  //         && { status: FilterScope.filterIn(stades) },
  //   }));
  // };

  // const handleEtiquetteChange = ({ etiquettes }) => {
  //   setSelectedEtiquettes(etiquettes);
  //   handleQueryChange(etiquettes, selectedStades);
  // };

  // const handleStadeChange = (e, { value }) => {
  //   let newStades = [];
  //   if (selectedStades.includes(value)) {
  //     newStades = selectedStades.filter((selectedStade) => selectedStade !== value);
  //   } else {
  //     newStades = [...selectedStades, value];
  //   }
  //   setSelectedStades(newStades);
  //   handleQueryChange(selectedEtiquettes, newStades);
  // };

  const history = useHistory();
  const handleClick = () => {
    history.push('/projets');
  };

  return (
    <Liste
      title="Projets"
      mode={mode}
      searchable
      search={{ placeholder: "Rechercher sur l'intitulé et la référence interne" }}
      // @ts-expect-error
      persistState={false}
      onExpand={handleClick}
      {...handlers}
    >
      <Liste.Header>
        {
          mode !== 'explorer' && (
            <Header size="small">
              {count}
              {' '}
              {(count > 1) ? 'projets' : 'projet' }
            </Header>
          )
          /* : (
            <Form>
              <Form.Group>
                <Form.Field
                  control={EtiquettesInput}
                  allowAdditions={false}
                  identifiantEntite={sessionStorage.getItem('identifiantEntite')}
                  onChange={handleEtiquetteChange}
                  selectedEtiquettes={selectedEtiquettes}
                  dropdownProps={{ className: 'etiquettes' }}
                />
                <div className="filterCheckboxed">
                  <Form.Checkbox
                    label="Conception"
                    value={1}
                    checked={selectedStades.includes(1)}
                    onChange={handleStadeChange}
                  />
                  <Form.Checkbox
                    label="Planification"
                    value={2}
                    checked={selectedStades.includes(2)}
                    onChange={handleStadeChange}
                  />
                  <Form.Checkbox
                    label="Exécution"
                    value={3}
                    checked={selectedStades.includes(3)}
                    onChange={handleStadeChange}
                  />
                  <Form.Checkbox
                    label="Soldé"
                    value={4}
                    checked={selectedStades.includes(4)}
                    onChange={handleStadeChange}
                  />
                  <Form.Checkbox
                    label="Aucun"
                    value={0}
                    checked={selectedStades.includes(0)}
                    onChange={handleStadeChange}
                  />
                </div>
              </Form.Group>
            // </Form>
          ) */
        }
      </Liste.Header>
      <Liste.Content>
        <ProjetListeTable
          items={projets}
          loading={loading}
          count={count}
          perPage={perPage}
          onLoad={onLoad}
          onSort={onSort}
          onSelect={onSelected}
          isExplorer={mode === 'explorer'}
        />
      </Liste.Content>
    </Liste>
  );
};

export default ProjetListe;
